<template>
  <div class="container">
    <div class="warpFun">
      <div class="purchaseSalesman">
        <el-row class="meRow">
          <el-col :span="4" class="inputs">
            <el-button type="primary" @click="goSearch">查询</el-button>
            <el-button type="primary" @click="goReset">重置</el-button>
          </el-col>
          <el-col :span="3" class="inputn">
            <download-excel
              class="export-excel-wrapper"
              :data="json_data"
              :fields="json_fields"
              name="网店订单.xls"
            >
              <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
              <el-button type="primary" class="op-btn2" @click="goExport"
                >导出</el-button
              >
            </download-excel>
          </el-col>
        </el-row>
        <p class="solid"></p>

        <el-row class="meRow">
          <el-col :span="3">
            <el-select
              v-model="form.shopName"
              placeholder="店铺"
              @change="goSearch"
              clearable
            >
              <el-option
                v-for="item in shopNoList"
                :key="item.shopNo"
                :label="item.shopName"
                :value="item.shopNo"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select
              v-model="form.shopType"
              placeholder="店铺类型"
              @change="goSearch"
              clearable
            >
              <el-option
                v-for="item in shopTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-date-picker
              v-model="time"
              range-separator="-"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              @change="goSearch"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="3">
            <el-input
              onkeyup="this.value=this.value.replace(/[, ]/g,'')"
              v-model="form.keyword"
              placeholder="请输入商品名称，订单编号"
              clearable
            ></el-input>
          </el-col>

          <!-- <el-col :span="3">
            <el-select
              v-model="form.type"
              placeholder="订单类型"
              @change="goSearch"
              clearable
            >
              <el-option
                v-for="item in net_order_type"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col> -->
          <el-col :span="3">
            <el-select
              v-model="form.isExceptionOrder"
              placeholder="物流状态"
              clearable
              @change="goSearch"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="正常" value="0"></el-option>
              <el-option label="异常" value="1"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="orOption-item">
        <div class="orOption-btn cflex"></div>
      </div>
    </div>
    <div class="orTab cflex" id="myTriOrTab">
      <div
        class="ortabs"
        @click="tabClick(null)"
        :class="{ curr: curTab == null }"
        ref="ortabs0"
        id="ortabs0"
      >
        <!-- <img :src="orderIcon[0].active" alt="" v-if="form.curTab == null">
        <img :src="orderIcon[0].normal" alt="" v-else> -->
        <span>全部</span>
      </div>
      <div
        class="ortabs"
        v-for="(item, index) in net_order_state"
        :key="index"
        :id="'ortabs' + (index + 1)"
        :ref="'ortabs' + (index + 1)"
        :class="{ curr: curTab == item.value }"
        @click="tabClick(item.value)"
      >
        <!-- <img :src="orderIcon[index+1].active" alt="" v-if="form.curTab == item.value">
        <img :src="orderIcon[index+1].normal" alt="" v-else> -->
        <!-- <span v-if="item.value != 3 && item.value != 4">{{ item.text }}</span> -->
        <span>{{ item.text }}</span>
      </div>
    </div>
    <div class="orTable-inner">
      <div class="orTable-tri" :style="{ left: triLeft + 'px' }"></div>
      <div class="order-table">
        <div class="table-head">
          <div class="table-head-item w1">
            <el-checkbox
              :value="checkedAll"
              @change="ifAllChoose"
            ></el-checkbox>
            <span>商品ID</span>
          </div>
          <div class="table-head-item w2">订单信息</div>
          <div class="table-head-item w3">收货信息</div>
          <div class="table-head-item w4">订单状态</div>
          <div class="table-head-item w5">操作</div>
        </div>
        <div
          class="table-list"
          v-for="(item, index) in tableData"
          :key="index"
          :class="{ expediting: item.remindFlag == 1 && item.status == 1 }"
        >
          <div class="table-list-up">
            <span>订单号:{{ item.orderNo }}</span>
            <span>下单时间:{{ item.orderTime }}</span>
            <!-- <el-button>打印发货单</el-button> -->
          </div>
          <div class="table-list-center">
            <div class="table-list-cli w1 c1">
              <el-checkbox
                :value="item.ifSelect"
                @change="ifSingleChoose(index)"
              ></el-checkbox>
              <span>{{ item.id }}</span>
              <div class="orImg">
                <img
                  :src="fileRequest + item.goodsImg"
                  alt=""
                  v-if="item.goodsImg != '' && item.goodsImg != null"
                />
                <img src="~@/assets/icon/goods-init.png" alt="" v-else />
                <span>{{ item.orderType == 0 ? "普" : "活" }}</span>
              </div>
            </div>
            <div class="table-list-cli w2 c2">
              <span>{{ item.goodsName }} {{ item.specValue }}</span>
              <span>￥{{ item.price }}</span>
            </div>
            <div class="table-list-cli w3 c3">
              <span>{{ item.takeDeliveryPerson }} {{ item.telephone }}</span>
              <span>{{ item.deliveryAddress }}</span>
            </div>
            <div class="table-list-cli w4 c4">
              <span>{{ item.statusTxt }}</span>
            </div>
            <div class="table-list-cli w5 c5">
              <span @click="getDetails(item.orderNo, item.shopType)"
                >订单详情</span
              >
            </div>
          </div>
          <div class="table-list-bottom">
            <div class="table-list-bli b1">
              <span>支付方式:{{ item.payTypeTxt }}</span>
              <span
                >配送方式:{{
                  item.companyName == null ? "" : item.companyName
                }}</span
              >
              <span>{{ item.isExceptionOrder == 0 ? "" : "物流异常" }}</span>
            </div>
            <div class="table-list-bli b2">
              <span>共{{ item.num }}件商品</span>
              <span>合计:￥{{ item.price }}</span>
              <span>(含运费:￥{{ item.fare }})</span>
            </div>
          </div>
        </div>
        <div class="table-warning" v-if="tableData.length == 0"></div>
      </div>
      <div class="pagination">
        <my-Pagination
          v-show="total > 0"
          :total="total"
          :pageSize="form.limit"
          :page.sync="form.page"
          :limit.sync="form.limit"
          @pagination="pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { orderNetList } from "@/api/order/orderAPI";
import myPagination from "@/components/myPagination";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("user");
export default {
  name: "onlineManage",
  components: {
    myPagination,
  },
  data() {
    return {
      shopNoList: null,
      shopTypeList: [
        {
          name: "网店",
          value: 1,
        },
        {
          name: "优店",
          value: 2,
        },
      ],
      orderIcon: this.$store.getters.orderIcon,
      version: this.$store.getters.copyright,
      fileRequest: this.$store.getters.fileRequest,
      checkedAll: false, // 表格全选
      tableData: [],
      form: {
        page: 1,
        limit: 10,
        keyword: null,
        status: null,
        type: null,
        startTime: null,
        endTime: null,
        isExceptionOrder: null,
        shopName: null, //店铺
        shopType: null, //店铺类型
      },
      form1: {
        shopNo: this.$store.getters.shopNo,
        page: 1,
        limit: 10,
        status: null,
      },
      curTab: null,
      time: null,
      total: 10,
      triLeft: 0,
      json_fields: {
        订单号: "orderNo", //常规字段
        下单时间: "orderTime", //支持嵌套属性
        编号: "id", //支持嵌套属性
        下单类型: "orderTypeTxt", //支持嵌套属性
        商品名称: "goodsName", //支持嵌套属性
        商品规格: "specValue",
        商品价格: "price",
        商品数量: "num",
        收货人: "takeDeliveryPerson",
        收货号码: "telephone",
        收货地址: "deliveryAddress",
        订单状态: "statusTxt",
        // "头部-损坏区域代码": {
        //   field: "phone.landline",
        //             //自定义回调函数
        //   callback: value => {
        //     return `损坏区域代码 - ${value}`;
        //   }
        // }
      },
      json_meta: [
        [
          {
            " key ": " charset ",
            " value ": " utf- 8 ",
          },
        ],
      ],
      json_data: [],
    };
  },
  mounted() {
    this.shopNoList = this.$store.getters.userInfo.shopNoList;
    if (this.$route.query.sta) {
      this.tabClick(this.$route.query.sta);
    } else if (this.$route.query.sts) {
      this.form.status = this.$route.query.sts;
      this.dataInit();
    } else if (this.$route.query.wl) {
      this.form.isExceptionOrder = this.$route.query.wl;
      this.dataInit();
    } else {
      this.dataInit();
    }
  },
  computed: {
    ...mapGetters([
      "order_state",
      "net_order_state",
      "net_order_type",
      "netOrderPayType",
    ]),
  },
  watch: {
    curTab: {
      handler(newVal) {
        this.$nextTick(() => {
          if (this.$refs.ortabs0) {
            if (newVal == null) {
              let w1 = this.$refs.ortabs0.clientWidth;
              this.triLeft = w1 / 2;
            } else {
              let currTab = this.$refs["ortabs" + (parseInt(newVal) + 1)][0]; // 当前选项
              let wid = currTab.clientWidth; //元素宽度
              let parentOffset =
                document.getElementById("myTriOrTab").offsetLeft;
              let w2 = document.getElementById(
                "ortabs" + (parseInt(newVal) + 1)
              ).offsetLeft;
              this.triLeft = w2 - parentOffset + wid / 2 - 10;
            }
          }
        });
      },
      immediate: true,
      deep: true,
    },
    time: {
      handler(newVal) {
        if (newVal != null) {
          this.form.startTime = this.time[0];
          this.form.endTime = this.time[1];
        }
      },
    },
  },
  created() {
    console.log(this.$store.getters.fileRequest);
  },
  methods: {
    getDetails(val, shopType) {
      if (shopType == 1) {
        this.$router.push({
          path: "/online/childCom/details",
          query: { orderNo: val, shopType },
        });
      } else {
        this.$router.push({
          path: "online/childCom/detailsYo",
          query: { orderNo: val },
        });
      }
    },
    //全选
    ifAllChoose() {
      if (this.checkedAll) {
        this.checkedAll = false;
        this.tableData.forEach((ele) => {
          ele.ifSelect = false;
          this.json_data = [];
        });
      } else {
        this.checkedAll = true;
        this.tableData.forEach((ele) => {
          ele.ifSelect = true;
          this.json_data = this.tableData;
        });
      }
    },
    //单选
    ifSingleChoose(i) {
      if (this.tableData[i].ifSelect) {
        let id = this.tableData[i].id;
        this.tableData[i].ifSelect = false;
        this.checkedAll = false;
        this.json_data = this.json_data.filter((item) => item.id != id);
      } else {
        this.tableData[i].ifSelect = true;
        if (!this.tableData.some((n) => n.ifSelect == false)) {
          this.checkedAll = true;
        }
        this.json_data.push(this.tableData[i]);
      }
    },
    // 获取列表参数
    async tabClick(i) {
      console.log("这一步");
      this.curTab = i;
      this.form.status = i;
      console.log(i);
      console.log(this.$store.getters.userInfo.merchantNo);
      let obj = {
        params: {
           merchantNo: this.$store.getters.userInfo.merchantNo,
          status: this.form.status,
          limit: this.form.limit,
          page: this.form.page,
          startTime: this.form.startTime,
          endTime: this.form.endTime,
          isExceptionOrder: this.form.isExceptionOrder,
          type: this.form.type,
          status: this.form.status,
          keyword: this.form.keyword,
          shopNo: this.form.shopName,
          shopType: this.form.shopType,
          v: this.version, //订单状态 0:待支付 1:待发货 2：待收货 3:待评价 4:已评价,5:已取消 6:退款中 7:已退款
        },
      };

      const res = await orderNetList(obj);
      const { flag, data, msg } = res.data;
      if (flag) {
        data.records.forEach((ele) => {
          ele.statusTxt = this.net_order_state.find(
            (n) => n.value == ele.status
          ).text;
          if (ele.payType == "" || ele.payType == null) {
            ele.payTypeTxt = "无";
            console.log("111");
          } else {
            ele.payTypeTxt =
              this.netOrderPayType.find((n) => n.value == ele.payType) != null
                ? this.netOrderPayType.find((n) => n.value == ele.payType).text
                : "";
          }
        });
        this.tableData = data.records;

        this.total = data.total;
      } else {
        this.$message({
          type: "error",
          message: msg,
        });
      }
    },
    async dataInit() {
      let obj = {
        // params: this.form,
        params: {
          merchantNo: this.$store.getters.userInfo.merchantNo,
          status: this.form.status,
          limit: this.form.limit,
          page: this.form.page,
          startTime: this.form.startTime,
          endTime: this.form.endTime,
          isExceptionOrder: this.form.isExceptionOrder,
          type: this.form.type,
          status: this.form.status,
          keyword: this.form.keyword,
          shopNo: this.form.shopName,
          shopType: this.form.shopType,
          v: this.version,
        },
      };
      console.log(obj);
      const res = await orderNetList(obj);
      const { flag, data, msg } = res.data;
      if (flag) {
        data.records.forEach((ele) => {
          ele.ifSelect = false;
          ele.orderTypeTxt = ele.orderType == 0 ? "普" : "活";

          ele.statusTxt = this.net_order_state.find(
            (n) => n.value == ele.status
          ).text;
          if (ele.payType == "" || ele.payType == null) {
            ele.payTypeTxt = "无";
          } else {
            console.log(
              this.netOrderPayType.find((n) => n.value == ele.payType)
            );
            ele.payTypeTxt = this.netOrderPayType.find(
              (n) => n.value == ele.payType
            ).text;
          }
        });
        this.tableData = data.records;
        this.total = data.total;
      } else {
        this.$message({
          type: "error",
          message: msg,
        });
      }
    },
    //分页触发
    pagination(val) {
      this.form.page = val.page;
      this.form.limit = val.limit;
      this.dataInit();
    },
    goSearch() {
      this.dataInit();
    },
    goReset() {
      this.form.name = null;
      this.time = null;
      this.form.startTime = null;
      this.form.endTime = null;
      this.form.status = null;
      this.form.type = null;
      this.form.keyword = null;
      this.form.shopName = null;
      this.form.shopType = null;
      this.form.isExceptionOrder = null
      this.form.page = 1;
      this.form.limit = 10;
      this.dataInit();
    },
    goExport() {
      if (this.json_data.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择需要导出的数据",
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.ifFresh) {
      to.meta.keepAlive = false;
    } else {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="less" scoped>
.export-excel-wrapper {
  display: none;
}
.orTable-inner {
  height: 800px;
  background: #fff;
  border-radius: 10px;
  padding: 0 76px 0 16px;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  .orTable-tri {
    width: 0;
    height: 0;
    border-bottom: 1.2rem solid #fff;
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
    position: absolute;
    left: 0;
    top: -10px;
  }
  .el-radio-group {
    width: 100%;
    margin-bottom: 16px;
  }
  .order-table {
    width: 100%;
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    /deep/ .el-checkbox__inner:hover {
      background: #21d08a !important;
      border-color: #21d08a;
    }
    /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #21d08a;
    }
    /deep/ .el-checkbox__inner::after {
      width: 6px;
      height: 10px;
    }
  }
  .table-head {
    height: 5.2rem;
    font-size: 1.5rem;
    color: #333333;
    line-height: 52px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /deep/ .table-head-item.w1 {
      text-align: left;
      .el-checkbox {
        margin-left: 10px;
        margin-right: 13px;
        .el-checkbox__inner {
          width: 18px;
          height: 18px;
          background: #e0e1e2;
          border-radius: 4px;
        }
      }
    }
  }
  .w1 {
    flex: 231 1 0;
  }
  .w2 {
    flex: 255 1 0;
    text-align: left;
  }
  .w3 {
    flex: 320 1 0;
    text-align: left;
  }
  .w4 {
    flex: 120 1 0;
    text-align: left;
  }
  .w5 {
    flex: 131 1 0;
  }
  .table-list {
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    margin-bottom: 12px;
    .table-list-up {
      height: 4.2rem;
      line-height: 4.2rem;
      font-size: 1.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #f8f8f8;
      border-radius: 4px;
      padding: 0 2rem 0 1.2rem;
      span {
        margin-right: 1.8rem;
      }
      .el-button {
        width: 8.8rem;
        height: 3rem;
        margin-left: auto;
        background: linear-gradient(270deg, #4519fa 0%, #4a8cff 100%);
        border-radius: 1.5rem;
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: #fff;
        padding: 0;
      }
    }
    .table-list-center {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2.4rem 0;
      .table-list-cli {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.c1 {
          align-items: flex-start;
          /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
          /deep/ .el-checkbox__inner:hover {
            background: #21d08a !important;
            border-color: #21d08a;
          }
          /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
            border-color: #21d08a;
          }
          /deep/ .el-checkbox__inner::after {
            width: 6px;
            height: 10px;
          }
          /deep/ .el-checkbox__inner {
            width: 18px;
            height: 18px;
            background: #e0e1e2;
            border-radius: 4px;
            margin-right: 1.8rem;
            margin-left: 1rem;
          }
        }
        span {
          display: inline-block;
          height: 1.4rem;
          line-height: 2rem;
          font-size: 1.4rem;
          color: #000000;
        }
        .orImg {
          margin-left: auto;
          margin-right: 1rem;
          width: 8rem;
          height: 8rem;
          position: relative;
          img {
            display: block;
            width: 8rem;
            height: 8rem;
            border-radius: 4px;
          }
          span {
            display: block;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            border: 0.3rem solid #fff;
            text-align: center;
            line-height: 1.6rem;
            font-size: 1rem;
            background: linear-gradient(180deg, #ff5b93 0%, #ff70e7 100%);
            color: #ffffff;
            position: absolute;
            left: -0.3rem;
            bottom: -0.3rem;
          }
        }
        &.c2 {
          flex-direction: column;
          span {
            display: inline-block;
            height: auto;
            padding-right: 2rem;
            line-height: 1.4rem;
            font-size: 1.4rem;
            color: #000000;
            margin-bottom: 2rem;
          }
        }
        &.c3 {
          flex-direction: column;
          span {
            display: inline-block;
            height: auto;
            padding-right: 2rem;
            line-height: 1.4rem;
            font-size: 1.4rem;
            color: #000000;
          }
        }
        &.c4 span {
          display: inline-block;
          height: auto;
          padding-left: 0.8rem;
          line-height: 2.2rem;
          font-size: 1.6rem;
          color: #000000;
        }
        &.c5 span {
          display: inline-block;
          width: 100%;
          height: auto;
          text-align: center;
          line-height: 2.2rem;
          font-size: 1.4rem;
          color: #1955fa;
          cursor: pointer;
        }
      }
    }
    .table-list-bottom {
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f8f8f8;
      border-radius: 4px;
      padding: 0 2rem 0 1rem;
      font-size: 1.4rem;
      color: #000000;
      .b1 span {
        margin-right: 3.6rem;
      }
      .b2 {
        padding-right: 3.6rem;
        span {
          margin-left: 1rem;
        }
      }
    }
  }
}
.pagination {
  padding: 10px 0 50px;
}
.orOption {
  background: #fff;
  border-radius: 10px;
  padding: 15px 73px 26px 15px;
}
.orOption-box {
  width: 64%;
}
.orOption-btn {
  width: 33.3%;
}
.orOption-item .or-w1 {
  width: 317px;
  /deep/ input {
    width: 100%;
  }
}
.orOption-item .or-w2 {
  flex: 258 1 0;
}
.orOption-item .or-w3 {
  flex: 144 1 0;
}
.or-mar1 {
  margin: 0 1rem;
}
.or-mar2 {
  margin-right: 1rem;
}
.or-w3 span {
  white-space: nowrap;
}
.orOption-item /deep/ input,
.orOption-item /deep/ ::placeholder {
  font-size: 14px !important;
  color: #333333 !important;
  background: #f8f8f8;
  border-radius: 20px;
  height: 3rem;
  line-height: 3rem;
}
.orOption-item /deep/ .el-input .el-select__caret {
  line-height: 3rem;
}
.orOption-item /deep/ .el-date-editor {
  background: #f8f8f8;
  border-radius: 20px;
  height: 3rem;
  line-height: 3rem;
  input {
    height: 2.8rem;
    line-height: 2.8rem;
    background: none;
  }
  .el-input__icon,
  .el-range-separator {
    line-height: 2.2rem;
  }
}
.orOption-item:last-child {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.op-btn1 {
  width: 100px;
  height: 3rem;
  border-radius: 20px;
  background: linear-gradient(270deg, #4519fa 0%, #4a8cff 100%) !important;
  padding: 0;
  font-size: 1.4rem;
  color: #ffffff;
  margin-left: auto;
}
.op-btn2 {
  width: 112px;
  height: 3rem;
  border-radius: 20px;
  background: none !important;
  padding: 0;
  font-size: 1.4rem;
  color: #1955fa !important;
  border-color: #1955fa !important;
  margin-left: 14px;
}
.orTab {
  background: #fff;
  border-radius: 10px;
  padding: 11px 59px 0px 15px;
  margin: 12px 0 14px;
  height: auto;
  flex-wrap: wrap;
}
.ortabs span {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;
  padding: 0 3rem;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 11px;
  flex: 1 1 0;
  max-width: 10rem;
}
.ortabs:last-child span {
  margin-right: 0;
}
.ortabs img {
  margin-right: 1.2rem;
}
.ortabs span {
  font-size: 1.6rem;
  color: #333;
}
.ortabs.curr span {
  height: 3rem;

  background: #fff !important;
  color: #4a8cff !important;
  border: 1px solid #4a8cff !important;
}
.ortabs.curr span {
  color: #fff;
}
.expediting {
  box-shadow: 0 0 6px 1.2px #ff0000;
  position: relative;
  &::after {
    display: block;
    content: "催货中";
    width: auto;
    height: 20px;
    line-height: 20px;
    color: #fff;
    font-size: 12px;
    border-radius: 10px;
    padding: 0 5px;
    background: #ff0000;
    position: absolute;
    right: -10px;
    top: -10px;
  }
}
.table-warning {
  width: 20%;
  height: 30rem;
  margin: 10rem auto;
  background: url("~@/assets/new-icon/Group Copy@3x.png") no-repeat;
  background-size: 80%;
  position: relative;
}

.classify-inner-dialog-style {
  /deep/ .el-dialog__header {
    padding: 0px;
  }
  /deep/ .el-dialog__body {
    padding: 20px 30px;
  }
}
.meRow {
  width: 100%;
}
@media screen and (max-width: 1700px) {
  .meRow {
    width: 1690px !important;
  }
}
</style>
