import request from "../../utils/request";
let baseUrl = process.env.VUE_APP_BASE_URL2;

// 查询订单分页列表
export function orderNetList(params) {
  return request({
    url: `${baseUrl}/order/smart/merchant/listPage/v260`,
    method: "POST",
    data: params
  });
}

// 网店订单详情
export function orderNetListDetail(orderNo) {
  return request({
    url: `${baseUrl}/order/net/merchant/detail/${orderNo}/v250`,
    method: "GET"
  });
}

// 优店订单详情
export function orderNetListDetailyo(orderNo) {
  return request({
    url: `${baseUrl}/order/yo/merchant/detail/${orderNo}/v250`,
    method: "GET"
  });
}


//查询快递名称和编号
export function logisticsName(deliveryNo) {
  return request({
    url: `${baseUrl}/order/express/delivery/check/${deliveryNo}/v250`,
    method: "GET"
  });
}


// 确认发货
export function consignment(params) {
  return request({
    url: `${baseUrl}/order/net/merchant/consignment/v250`,
    method: "POST",
    data: params
  });
}
// 实时物流追踪
export function logisticsDetail(deliveryNo) {
  return request({
    url: `${baseUrl}/order/express/delivery/realTime/${deliveryNo}/v250`,
    method: "GET"
  });
}

// 配送
export function yoDistribution(orderNo) {
  return request({
    url: `${baseUrl}/order/yo/merchant/distribution/${orderNo}/v250`,
    method: "POST",
  });
}